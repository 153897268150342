import { useContent } from '@hooks/use-server-content';

export interface OrderDetailsContent {
    orderDetailsbreadcrumbTitle: string;
    orderDetailspageTitle: string;
    order: string;
    orderDate: string;
    billingAddress: string;
    shippingAddress: string;
    startReturn: string;
    returnPolicy: string;
    returnPolicyButtonAriaLabel?: string;
    returnPolicyPageUrl?: string;
    multipleQuantityPriceDescriptor: string;
    quantity: string;
    cancelButtonLabel: string;
    cancelButtonAriaLabel: string;
    buyAgain: string;
    orderSubTotal: string;
    orderShipping: string;
    orderSalesTax: string;
    orderTotal?: string;
    returnsbreadcrumbTitle?: string;
    returnspageTitle?: string;
    returnspageHeader?: string;
    nonReturnable?: string;
    freeShipping?: string;
    returnPolicyButtonCreateReturnLabelText?: string;
    returnPolicyButtonCreateReturnAriaLabel?: string;
    returnsSupportInfoText?: string;
    returnsDropdown?: string;
    continue?: string;
    returnsModalTitle?: string;
    returnsModalSubtitle?: string;
    changeSelection?: string;
    confirmReturn?: string;
    faqTitle?: string;
    faqItem?: string;
    completeReturnPageHeaderText?: string;
    completeReturnPageSubheaderText?: string;
    completeReturnPageItemsToReturnText?: string;
    completeReturnPageReturnSlipButtonText?: string;
    completeReturnPageReturnSlipButtonAriaLabel?: string;
    completeReturnPageReturnLabelButtonText?: string;
    completeReturnPageReturnLabelButtonAriaLabel?: string;
    createReturnBreadcrumbTitle?: string;
    createReturnBreadcrumbPreviousTitle?: string;
    createReturnBreadcrumbSecondPreviousTitle?: string;
    errorNotificationMessage?: string;
    createReturnErrorMessage?: string;
    packingSlipErrorNotificationMessage?: string;
    returnLabelErrorNotificationMessage?: string;
    guestReturnBreadcrumbTitle?: string;
    guestDetailsBreadcrumbTitle?: string;
    productPageUrl?: string;
    accessoriesUrl?: string;
    accessoriesImageUrl?: string;
    performancePartsImageUrl?: string;
    dealerPickUpAtText: string;
    returnPickupItemModalHeaderText: string;
    returnPickupItemModalBodyText: string;
    returnPickupItemModalCloseButtonAriaLabel: string;
    shipReturnButtonLabelText: string;
    shipReturnButtonAriaLabel: string;
    getDropOffLocationButtonLabelText: string;
    getDropOffLocationButtonAriaLabel: string;
    returnToDealerPageHeaderText: string;
    returnToDealerPageDescriptionText: string;
    returnToDealerModalHeaderText: string;
    returnToDealerModalBodyText: string;
    cancelModalHeader: string;
    cancelItemButtonLabel: string;
    cancelItemButtonAriaLabel: string;
    keepInOrderButtonLabel: string;
    keepInOrderButtonAriaLabel: string;
    cancellationStatusBannerSuccessMessage: string;
    cancellationStatusBannerStatusChangedFailureMessage: string;
    cancellationStatusBannerAPIFailureMessage: string;
}

export const useOrderDetailsContent = (): OrderDetailsContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-order-detail');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as OrderDetailsContent;
};
